import React from 'react'

const WorkingCol = ({ working }) => {
    const isNotZeroWorking = working.count > 0
    return (
        <p className={`department_employe_count_mobile ${working.top === 1 && isNotZeroWorking ? "top_color_red" : working.top === 2 && isNotZeroWorking ? "top_color_orange" : "default_color"}`}>
            {working.count}
        </p>
    )
}

export default WorkingCol