import { createSlice } from "@reduxjs/toolkit";
import { REACT_APP_PASSWORD } from "../../consts/consts";

const initialState = {
    isLoading: true,
    next: false,
    timeout: 0,
    auth: localStorage.getItem(REACT_APP_PASSWORD)
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        nextList: (state) => {
            state.next = true;
        },
        setTimeOutId: (state, { payload }) => {
            state.timeout = payload;
        },
        stopList: (state) => {
            state.next = false;
        },
        setList: (state) => {
            state.next = !state.next;
        },
        signOut: (state) => {
            localStorage.removeItem('user_hash')
            state.auth = false
        },
        signIn: (state) => {
            localStorage.setItem(REACT_APP_PASSWORD, true)
            state.auth = true
        },
    },
});

export const { setLoading, stopList, nextList, setList, setTimeOutId, signOut, signIn } = uiSlice.actions;

export default uiSlice.reducer;
