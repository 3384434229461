import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Message from './components/Notifications/Message'
import './index.css';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(

  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <Message />
  </Provider>
);