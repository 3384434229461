import axios from "axios";
import { REACT_APP_BASE_URL_DEV, REACT_APP_BASE_URL_PROD, REACT_APP_PRODUCTION, REACT_APP_NEST_API } from "../../consts/consts";

export const api = axios.create({
    baseURL: REACT_APP_PRODUCTION ? REACT_APP_BASE_URL_PROD : REACT_APP_BASE_URL_DEV,
});

export const nestApi = axios.create({
    baseURL: REACT_APP_NEST_API
});

console.log('Base url is taken from', REACT_APP_PRODUCTION ? '"Production"' : '"Development"',)