import React, { useEffect, useRef, useState } from 'react'
import RowSubdivision from './RowSubdivision'
import CloseBtn from '../../assests/imgs/icons/X.png'
import accounting from '../../assests/imgs/subdivisions/accounting.png'
import expert_fields from '../../assests/imgs/subdivisions/expert_fields.png'
import outstaffing from '../../assests/imgs/subdivisions/outstaffing.png'
import project_managers from '../../assests/imgs/subdivisions/project_managers.png'
import remote_support from '../../assests/imgs/subdivisions/remote_support.png'
import repairing from '../../assests/imgs/subdivisions/repairing.png'
import service_center from '../../assests/imgs/subdivisions/service_center.png'
import sales_department from '../../assests/imgs/subdivisions/sales_department.png'
import server_experts from '../../assests/imgs/subdivisions/server_experts.png'
import BurgerWhite from '../../assests/imgs/icons/burder_white.png'
import BurgerBlack from '../../assests/imgs/icons/burder_black.png'

const imgs = {
    'd517c016-d41e-11ea-80f7-c4346b227f9c': remote_support,
    '11db1e7e-d41f-11ea-80f7-c4346b227f9c': sales_department,
    '23b49f17-d41f-11ea-80f7-c4346b227f9c': accounting,
    '991f9bb8-16d8-11ed-868d-c4346bb7a003': outstaffing,
    'e99f9b53-d41e-11ea-80f7-c4346b227f9c': expert_fields,
    'f354beb0-716d-11ed-868f-c4346bb7a003': repairing,
    'f0fbd43b-d41e-11ea-80f7-c4346b227f9c': service_center,
    'c003e454-3972-11ed-868e-c4346bb7a003': project_managers,
    'f82be32c-d41e-11ea-80f7-c4346b227f9c': server_experts,
}

const ColumnSubdivision = ({ subdivisions, changingList, choicesList, choiceGroupByID, cancleGroupByID }) => {

    const choicedCount = subdivisions.list.reduce((acc, subdivision) => {
        const isChoiced = choicesList.includes(subdivision.subdivision_uuid)
        return isChoiced ? acc + 1 : acc
    }, 0)
    const isAllChoiced = choicedCount === subdivisions.list.length


    const listForAnimationRef = useRef()
    const listRef = useRef()
    const burgerWhiteRef = useRef()
    const burgerBlackRef = useRef()
    const subTitleRef = useRef()
    const subCountRef = useRef()
    const choiceGroupBtnRef = useRef()
    const subBtnCloseRef = useRef()
    const [idTimeOut, setIdTimeOut] = useState(0)
    const [height, setHeight] = useState(0)

    const handleShowList = () => {
        if (!height) openSubdivisionList()
        else closeSubdivisionList()
    }

    const countRotateByDeg = (deg) => setRotationAngleCount(deg);
    const closeBtnRotateByDeg = (deg) => setRotationAnglCloseBtn(deg);
    const showCloseBtn = () => {
        countRotateByDeg(180)
        closeBtnRotateByDeg(180)
    }
    const showSubCount = () => {
        countRotateByDeg(360)
        closeBtnRotateByDeg(360)
    }

    const handleChangeList = () => {
        if (rotationAngleChoiceGroupBtn === 45) {
            CloseToPlus()
            cancleGroupByID(subdivisions.id)

        }
        else {
            plusToClose()
            choiceGroupByID(subdivisions.id)
        }
    }

    const plusToClose = () => {
        setRotationAngleChoiceGroupBtn(45)
        choiceGroupBtnRef.current.style.color = 'red'
    }
    const CloseToPlus = () => {
        setRotationAngleChoiceGroupBtn(0)
        choiceGroupBtnRef.current.style.color = ''
    }

    const TitleChanges = (status) => {
        const background = status === 'open' ? 'white' : '#4fc6e0'
        const color = status === 'open' ? '#4B4C4D' : 'white'

        subTitleRef.current.style.background = background;
        subTitleRef.current.style.color = color;
    }

    const burgerChanges = (status) => {
        burgerWhiteRef.current.style.opacity = status === 'open' ? 0 : 1
        burgerWhiteRef.current.style.zIndex = status === 'open' ? -1 : 1
        burgerBlackRef.current.style.zIndex = status === 'open' ? 1 : -1
        burgerBlackRef.current.style.opacity = status === 'open' ? 1 : 0
    }

    const openList = (status) => {
        if (status === 'open') {
            setHeight(listRef.current.offsetHeight)
            listForAnimationRef.current.style.top = `-${listRef.current.offsetHeight}px`
            setIdTimeOut(setTimeout(() => {
                listForAnimationRef.current.style.opacity = 1
            }, 400))
        }
        else {
            setHeight(0)
            listForAnimationRef.current.style.opacity = 0
            if (idTimeOut) clearTimeout(idTimeOut)
        }
    }

    const rotateBtnsZIndex = (status) => {
        if (status === 'open') {
            subCountRef.current.style.zIndex = -1;
            subBtnCloseRef.current.style.zIndex = 1
        } else {
            subCountRef.current.style.zIndex = 1;
            subBtnCloseRef.current.style.zIndex = -1
        }
    }

    const [rotationAngleCount, setRotationAngleCount] = useState(0);
    const [rotationAngleCloseBtn, setRotationAnglCloseBtn] = useState(90);
    const [rotationAngleChoiceGroupBtn, setRotationAngleChoiceGroupBtn] = useState(0)

    const openSubdivisionList = () => {
        showCloseBtn()
        openList('open')
        TitleChanges('open')
        burgerChanges('open')
        rotateBtnsZIndex('open')
    }

    const closeSubdivisionList = () => {
        showSubCount()
        openList('close')
        TitleChanges('close')
        burgerChanges('close')
        rotateBtnsZIndex('close')
    }

    const styleCount = { transform: `rotateY(${rotationAngleCount}deg)`, };
    const styleCloseBtn = { transform: `rotateY(${rotationAngleCloseBtn}deg)`, };
    const choiceGroupBtnStyle = { transform: `rotate(${rotationAngleChoiceGroupBtn}deg)`, }
    const stylesChoicedSubs = { background: choicedCount ? '#f3bb1c' : null }

    useEffect(() => {
        if (isAllChoiced) {
            plusToClose()
        } else CloseToPlus()
    }, [isAllChoiced])

    return (
        <div className="subdivision_choice_item">
            <div className="subdivisions_choice_icon" onClick={handleShowList}>
                <div style={stylesChoicedSubs} className='subdivisions_choice_count_choiced' title='Количество выбранных подразделений'>{choicedCount}</div>
                <div ref={subCountRef} style={styleCount} className='subdivisions_subdivisions_count'>{subdivisions.list.length}</div>
                <div ref={subBtnCloseRef} style={styleCloseBtn} className="subdivisions_choice_btn_close">
                    <img src={CloseBtn} />
                </div>
                <div className='subdivision_choice_item_icon_img'>
                    <img src={imgs[subdivisions.group_uuid]} />
                    <div style={{ width: 30 }}></div>
                </div>
            </div>
            <div className='subdivisions_choice_title_block'>
                <div ref={subTitleRef} onClick={handleShowList} className="subdivisions_choice_title">
                    {subdivisions?.group_name}
                    <img className='subdivisions_choice_burger subdivisions_choice_burger_white' ref={burgerWhiteRef} src={BurgerWhite} />
                    <img className='subdivisions_choice_burger subdivisions_choice_burger_black' ref={burgerBlackRef} src={BurgerBlack} />
                </div>
                <div className='subdivisions_choice_title_btn_add_block' onClick={handleChangeList}>
                    <div ref={choiceGroupBtnRef} style={choiceGroupBtnStyle} className='subdivisions_choice_title_btn_add'>+</div>
                </div>
            </div>
            <div className='subdivisions_choice_for_open_list' style={{ minHeight: height }}>

            </div >
            <div style={{ position: 'relative', width: '95%' }}>
                <div style={{ position: 'absolute', width: '100%' }}>
                    <div ref={listRef} >
                        <div ref={listForAnimationRef} style={{ top: -height }} className='subdivisions_choice_list_block'>
                            {
                                subdivisions.list.map((subdivision) => <RowSubdivision key={subdivision.subdivision_uuid} subdivision={subdivision} changingList={changingList} choicesList={choicesList} />)
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ColumnSubdivision




