import React, { useEffect, useRef } from 'react'
import Add from '../../assests/imgs/icons/add.png'
import Remove from '../../assests/imgs/icons/remove.png'
import './RowSubdivision.css'

const RowSubdivision = ({ subdivision, changingList, choicesList }) => {

    const isChoised = choicesList.includes(subdivision.subdivision_uuid)

    const btnAddRef = useRef()
    const btnCancleRef = useRef()
    const titleRef = useRef()

    const changeStatus = () => {
        changingList(subdivision.subdivision_uuid)
    }

    useEffect(() => {
        if (isChoised) {
            btnAddRef.current.style.zIndex = -1
            btnAddRef.current.style.opacity = 0
            btnCancleRef.current.style.opacity = 1
            titleRef.current.style.fontWeight = 600
        } else {
            btnAddRef.current.style.zIndex = 1
            btnAddRef.current.style.opacity = 1
            btnCancleRef.current.style.opacity = -1
            titleRef.current.style.fontWeight = 400
        }
    }, [isChoised])


    return (
        <div className='subdivisions_choice_list_block_row' onClick={changeStatus}>
            <p ref={titleRef} className='subdivisions_choice_list_block_row_title'>{subdivision.subdivision_name}</p>
            <div className='subdivisions_choice_list_block_row_changing' >
                <div className='subdivisions_choice_list_changing_block'>
                    <img ref={btnAddRef} className='subdivisions_choice_list_block_row_add' src={Add} alt="Добавить в список" />
                    <img ref={btnCancleRef} className='subdivisions_choice_list_block_row_cancle' src={Remove} alt="Убрать из списока" />
                </div>

            </div>
        </div>
    )
}

export default RowSubdivision