import { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextList, setTimeOutId } from "../../features/ui/uiSlice";
import { UPDATE_DELAY_BY_SECOND } from "../../consts/consts";
import Arrows from "../Arrows/Arrows";
import "./Progress.css";

const Progress = memo(({ nextDepartment, previousDepartment }) => {
    const progresBar = useRef();
    const dispatch = useDispatch();

    const { timeout } = useSelector((state) => state.ui);

    const update = () => {
        if (progresBar.current) {
            progresBar.current.innerHTML = "";
            const div = document.createElement("div");
            div.className = "progress_bar_animation_color";
            div.style.animationDuration = UPDATE_DELAY_BY_SECOND + "s";
            progresBar.current.appendChild(div);
        }
    };

    const refresh = () => {
        restartInterval()
    };

    const onNext = () => {
        refresh();
        nextDepartment()
    }

    const onPrevious = () => {
        refresh();
        previousDepartment()
    }

    const startInterval = () => {
        dispatch(
            setTimeOutId(
                setInterval(() => {
                    update();
                    dispatch(nextList());
                }, UPDATE_DELAY_BY_SECOND * 1000)
            )
        );

    }

    const restartInterval = () => {
        clearInterval(timeout);
        update()
        startInterval();
    }

    useEffect(() => {
        update();
        startInterval()
        return () => {
            clearInterval(timeout)
        }
    }, []);

    return <>
        <div ref={progresBar} className="progress_bar"></div>
        <Arrows onPrevious={onPrevious} onNext={onNext} />
    </>;
});

export default Progress;
