import React, { useEffect, useRef } from "react";
import ApplicationRow from "../header/ApplicationRow";
import EmployeRow from "./EmployeRow";
import { useSelector } from "react-redux";
import Slider from "@ant-design/react-slick";
import accounting from '../../assests/imgs/subdivisions/accounting.png'
import expert_fields from '../../assests/imgs/subdivisions/expert_fields.png'
import outstaffing from '../../assests/imgs/subdivisions/outstaffing.png'
import project_managers from '../../assests/imgs/subdivisions/project_managers.png'
import remote_support from '../../assests/imgs/subdivisions/remote_support.png'
import repairing from '../../assests/imgs/subdivisions/repairing.png'
import service_center from '../../assests/imgs/subdivisions/service_center.png'
import sales_department from '../../assests/imgs/subdivisions/sales_department.png'
import server_experts from '../../assests/imgs/subdivisions/server_experts.png'
import "./ApplicationLine.css";

const imgs = {
    'd517c016-d41e-11ea-80f7-c4346b227f9c': {
        img: remote_support,
        width: 80,
    },
    '11db1e7e-d41f-11ea-80f7-c4346b227f9c': {
        img: sales_department,
        width: 45,
    },
    '23b49f17-d41f-11ea-80f7-c4346b227f9c': {
        img: accounting,
        width: 45,
    },
    '991f9bb8-16d8-11ed-868d-c4346bb7a003': {
        img: outstaffing,
        width: 45,
    },
    'e99f9b53-d41e-11ea-80f7-c4346b227f9c': {
        img: expert_fields,
        width: 45,
    },
    'f354beb0-716d-11ed-868f-c4346bb7a003': {
        img: repairing,
        width: 45,
    },
    'f0fbd43b-d41e-11ea-80f7-c4346b227f9c': {
        img: service_center,
        width: 45,
    },
    'c003e454-3972-11ed-868e-c4346bb7a003': {
        img: project_managers,
        width: 45,
    },
    'f82be32c-d41e-11ea-80f7-c4346b227f9c': {
        img: server_experts,
        width: 45,
    },
}



const ApplicationLine = ({ implementers, subdivision, goScroll, cancleScroll }) => {

    const { allSubdivisionsList } = useSelector(state => state.implementers)
    const answer = allSubdivisionsList.find((department) => department.list.find(sub => sub.subdivision_uuid === subdivision.uuid))


    const { isLoading } = useSelector((state) => state.ui);
    const sliderRef = useRef();
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        waitForAnimate: false,
        focusOnSelect: true,
    };



    useEffect(() => {
        if (goScroll) {
            sliderRef.current.slickNext();
            cancleScroll()
        }
    }, [goScroll])


    useEffect(() => {
        sliderRef.current.slickGoTo(0, true);
    }, [implementers]);
    return (
        <>
            <div
                className="department_line"
                style={{ opacity: isLoading ? 0.6 : 1 }}
            >
                <Slider ref={sliderRef} {...settings}>
                    {implementers.map((implementer) => (
                        <EmployeRow
                            key={implementer.uuid}
                            name={implementer.name_rus}
                            planned={implementer.counts.planned}
                            working={implementer.counts.working}
                            paused={implementer.counts.paused}
                            finished={implementer.counts.finished}
                            cancled={implementer.counts.cancled}
                            apps={implementer.counts.apps}
                            total={implementer.total}
                        />
                    ))}
                </Slider>
            </div>

            <ApplicationRow lineSupport={subdivision.name_rus} orangeText={"15-30 мин"} redText={"> 30 мин "} img={imgs[answer.group_uuid]} uuid={subdivision.uuid} />
        </>
    );
};

export default ApplicationLine;
