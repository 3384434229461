import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubdivisonsInfo, refreshData } from '../../features/implementers/ImplemetnersSlice';
import { setLoading, stopList } from '../../features/ui/uiSlice';
import { UPDATE_DATA_BY_MINUTE, UPDATING_DELAY_BY_SECOND } from '../../consts/consts';
import Header from '../header/header';
import Updating from '../updating/Updating';
import Progress from '../progressbar/Progress';
import Main from '../content/main';
import MainMobile from '../content/mobile/MainMobile';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ implementers }) => {

    const isMobile = window.innerWidth < 900

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { noData, subdivisionsList } = useSelector((state) => state.implementers);
    const { next, isLoading } = useSelector((state) => state.ui);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [goScroll, setGoScroll] = useState(false)

    const currentImplementers = implementers ? implementers[currentIndex] : [];

    const [countScrolling, setCountScrolling] = useState(currentImplementers?.pages)

    const scroll = () => {
        setGoScroll(true)
        setCountScrolling(countScrolling - 1)
    }
    const cancleScroll = () => setGoScroll(false)

    const nextDepartment = () => {
        if (implementers.length - 1 === currentIndex) {
            dispatch(refreshData());
            setCurrentIndex(0);
        } else setCurrentIndex(currentIndex + 1);
    };

    const previousDepartment = () => {
        if (currentIndex === 0) setCurrentIndex(implementers.length - 1);
        else setCurrentIndex(currentIndex - 1);
    }

    useEffect(() => {
        setInterval(() => {
            dispatch(getSubdivisonsInfo({ update: true, navigate }));
        }, UPDATE_DATA_BY_MINUTE * 60000);
    }, []);

    useEffect(() => {
        if (!implementers?.length) dispatch(getSubdivisonsInfo({ list_uuids: subdivisionsList, navigate }));
        else setTimeout(() => dispatch(setLoading(false)), UPDATING_DELAY_BY_SECOND * 1000);
    }, [implementers]);

    useEffect(() => {
        if (next && !isLoading) {
            if (countScrolling) scroll()
            else nextDepartment()
            dispatch(stopList());
        }
    }, [next]);

    useEffect(() => {
        setCountScrolling(currentImplementers.pages)
    }, [currentImplementers])

    return (
        <div>
            <Header isMobile={isMobile} implementers={implementers} />
            {
                !isMobile ? isLoading ? <Updating noData={noData} /> : implementers.length ? <Progress nextDepartment={nextDepartment} previousDepartment={previousDepartment} /> : null : null
            }

            {
                !isMobile ?
                    <Main implementers={implementers[currentIndex]?.list} subdivision={implementers[currentIndex]?.subdivision} goScroll={goScroll} cancleScroll={cancleScroll} isMobile={isMobile} /> :
                    <MainMobile choicedSubdivisions={implementers} />
            }

        </div>
    )
}

export default Dashboard