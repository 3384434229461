import Dashboard from "./components/dashboard/Dashboard";
import ChoiceSubdivisions from "./components/choice/ChoiseSubdivisions";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import BasicAuth from "./app/auth/BasicAuth";
import Auth from "./app/auth/Auth";

function App() {

    const { implementers } = useSelector((state) => state.implementers.subdivisions);

    return (
        <Routes>
            <Route path="/" element={<Auth>{implementers?.length ? <Dashboard implementers={implementers} /> : <ChoiceSubdivisions />}</Auth>} />
            <Route path="/login" element={<BasicAuth />} />
        </Routes>
    );
}

export default App;
