import React from 'react'
import LogOutBtn from '../../assests/imgs/icons/signout_btn.png'

const ChoiceHeader = ({ reset, choiceAll, showData, logOut }) => {

    return (
        <div className="choice_header">
            <div className='choice_subdivision_title'>Выберите подразделения</div>
            <div className="choice_subdivision_btns">
                <button className='choice_subdivision_btn_reset' onClick={reset}>Сбросить</button>
                <button className='choice_subdivision_btn_choiceAll' onClick={choiceAll}>Выбрать все</button>
                <button className='choice_subdivision_btn_show' onClick={showData}>Перейти</button>
                <img className='choice_subdivision_btn_logout' onClick={logOut} src={LogOutBtn} />
            </div>
        </div>
    )
}

export default ChoiceHeader