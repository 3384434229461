import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../axios/axios.config";
import moment from "moment/moment";
import { setLoading } from "../ui/uiSlice";
import { createNotify } from "../../global/utils/utils";

const initialState = {
    allSubdivisionsList: [],
    allSubdivisionsUUIDs: [],
    subdivisions: [],
    subdivisionsLoad: [],
    subdivisionsList: [],
    counts: null,
    countsLoad: null,
    timeUpdated: null,
    timeUpdatedLoad: null,
    noData: false
};


export const getallSubdivisions = createAsyncThunk("implementers/getAllSubdivisions", async (_, { dispatch }) => {
    try {
        dispatch(setLoading(true))
        const resp = await api.post("dashboard/getAllSubdivisions");

        dispatch(setAllSubdivisions(resp.data))
        dispatch(setLoading(false))
        return resp.data;
    } catch (e) {
        console.error(e);
    }
});

export const getSubdivisonsInfo = createAsyncThunk("implementers/getSubdivisonsInfo", async ({ update, list_uuids }, { dispatch, getState }) => {
    try {

        const currentTime = moment().format("YYYY.MM.DD HH:mm");

        if (update) {
            const resp = await api.post(`dashboard/getSubdivisonsInfo/`, {
                subdivisions_uuid: list_uuids || getState().implementers.subdivisionsList
            });
            dispatch(setTimeUpdatedLoad(currentTime));
            dispatch(setSubdivisionsLoad(resp.data.data))
            dispatch(setCountsLoad(resp.data.data.counts));
            return resp.data;
        } else {
            dispatch(setLoading(true))
            const resp = await api.post(`dashboard/getSubdivisonsInfo/`, {
                subdivisions_uuid: list_uuids || getState().implementers.subdivisionsList
            });
            dispatch(setTimeUpdated(currentTime));
            dispatch(setSubdivisions(resp.data.data))
            dispatch(setCounts(resp.data.data.counts));
            dispatch(setSubdivisionsList(list_uuids))
            dispatch(setLoading(false))
            if (!resp.data.data.implementers.length) {
                createNotify('К сожалению нет данных')
            }
            return resp.data;
        }
    } catch (e) {
        console.error(e);
    }
});

const implementerSlice = createSlice({
    name: "implementers",
    initialState,
    reducers: {
        setAllSubdivisions: (state, { payload }) => {
            state.allSubdivisionsList = payload
            state.allSubdivisionsUUIDs = payload.reduce((acc, group) => [...acc, ...group.list
                .map(sub => sub.subdivision_uuid)], [])
        },
        setSubdivisions: (state, { payload }) => {
            state.subdivisions = payload
        },
        resetImplementersData: (state) => {
            state.subdivisions = []
        },
        setSubdivisionsLoad: (state, { payload }) => {
            state.subdivisionsLoad = payload
        },
        setSubdivisionsList: (state, { payload }) => {
            state.subdivisionsList = payload
        },
        setCounts: (state, { payload }) => {
            state.counts = payload;
        },
        setCountsLoad: (state, { payload }) => {
            state.countsLoad = payload;
        },
        setTimeUpdated: (state, { payload }) => {
            state.timeUpdated = payload;
        },
        setTimeUpdatedLoad: (state, { payload }) => {
            state.timeUpdatedLoad = payload;
        },
        refreshData: (state) => {
            if (state.subdivisionsLoad && state.countsLoad && state.timeUpdatedLoad) {
                state.subdivisions = state.subdivisionsLoad;
                state.counts = state.countsLoad;
                state.timeUpdated = state.timeUpdatedLoad;
            }
        },
        setNoData: (state, { payload }) => {
            state.noData = payload
        }
    },
});

export const {
    setCounts,
    setCountsLoad,
    setTimeUpdated,
    setTimeUpdatedLoad,
    refreshData,
    setNoData,
    setAllSubdivisions,
    setSubdivisions,
    setSubdivisionsList,
    setSubdivisionsLoad,
    resetImplementersData
} = implementerSlice.actions;

export default implementerSlice.reducer;
