import React from "react";
import Line_1 from '../../assests/imgs/subdivisions/linia_1.png'
import Line_2 from '../../assests/imgs/subdivisions/linia_2.png'
import Line_3 from '../../assests/imgs/subdivisions/linia_3.png'
import "./ApplicationRow.css";

const uuids_img = {
    '93c0d125-ec7e-11e8-80de-c4346b227f9c': Line_1,
    '9a802dac-ec7f-11e8-80de-c4346b227f9c': Line_2,
    '08f6a6aa-0517-11e9-80e0-c4346b227f9c': Line_3
}

const ApplicationRow = ({ lineSupport, orangeText, redText, img, uuid }) => {
    const image = uuids_img[uuid] || img.img

    return (
        <div className="department_block">
            <div className="department_block_content">
                <img className="department_block_img" style={{ width: img.width }} src={image} alt={lineSupport} />
                <span className="department_text">{lineSupport}</span>
            </div>
            <div className="departments dark-gray_color">
                <div className="departments_item">
                    <div className="department_header">
                        <div className="raunded bgOrange"></div>
                        <span className="department_header_text">{orangeText}</span>
                    </div>
                    <div className="department_header">
                        <div className="raunded bgRed"></div>
                        <span>{redText}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationRow;
