import React from "react";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import Back_Button from '../../assests/imgs/icons/btn_back.png'
import { resetImplementersData } from "../../features/implementers/ImplemetnersSlice";
import { useNavigate } from "react-router-dom";

const Header = ({ isMobile }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { timeUpdated, counts } = useSelector((state) => state.implementers);

    const queue = counts?.allPlannedCount + counts?.allWorkingCount + counts?.allPausedCount

    const resetData = () => {
        dispatch(resetImplementersData())
        navigate('/', { replace: true })
    }

    const goChoice = () => {
        window.location.reload();
    }

    return (
        <div className="header">
            <div className="header_content">
                <div className="header_blok">

                    <div className="header_first_blok">
                        <div className="header_first_blok_back_btn" onClick={resetData}>
                            <img src={Back_Button} />
                        </div>
                        <div className="header_first_blok_title" onClick={goChoice}>
                            <p className="header_title default_color">ITSG </p>
                            <p className="header_title_2 default_color">дашборд</p>

                        </div>
                    </div>
                    <div className="application_counts_mobile">
                        <p className="application_count default_color">{counts?.allApplicationsCount || 0}</p>
                        <p className="application_count_desc default_color">всего заявок</p>
                    </div>
                    <div className="header_second_block">
                        <img className="header_second_block_img" src="https://itsupport.kz/img/logo.png" alt="Itsupport" />

                    </div>
                </div>

                {
                    !isMobile ? <div className="header_applications">
                        <div>
                            <p className="application_count default_color">{queue}</p>
                            <p className="application_count_desc default_color">очередь</p>
                        </div>
                        <div>
                            <p className="application_count red_color">{counts?.allPlannedCount || 0}</p>
                            <p className="application_count_desc red_color">запланировано</p>
                        </div>
                        <div>
                            <p className="application_count orange_color">{counts?.allWorkingCount || 0}</p>
                            <p className="application_count_desc orange_color">в работе</p>
                        </div>
                        <div>
                            <p className="application_count linear_color">{counts?.allPausedCount || 0}</p>
                            <p className="application_count_desc linear_color">на паузе</p>
                        </div>
                        <div>
                            <p className="application_count green_color">{counts?.allFinishedCount || 0}</p>
                            <p className="application_count_desc green_color">завершено</p>
                        </div>
                        <div>
                            <p className="application_count gray_color">{counts?.allcancledCount || 0}</p>
                            <p className="application_count_desc gray_color">отменено</p>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    );
};

export default Header;
