import ApplicationLine from "./ApplicationLine";
import "./main.css";

const Main = ({ implementers, subdivision, goScroll, cancleScroll }) => {
    return (
        <div className="main">
            {implementers ?
                <ApplicationLine implementers={implementers} subdivision={subdivision} goScroll={goScroll} cancleScroll={cancleScroll} /> : null}
        </div>
    )
};

export default Main;
