import React from 'react'
import { useSelector } from 'react-redux';
import GroupRowMobile from './GroupRowMobile';
import './MainMobile.css'


const MainMobile = ({ choicedSubdivisions }) => {

    const counts = useSelector((state) => state.implementers.counts);

    const queue = counts?.allPlannedCount + counts?.allWorkingCount + counts?.allPausedCount

    return (
        <div className='main_mobile'>

            <div className="main_mobile_header">
                <div className='main_mobile_header_table_row minWidth290'>
                    <p>Исполнитель</p>
                </div>
                <div className='main_mobile_header_table_row'>
                    <p className='main_mobile_header_table_row_title default_color'>очередь</p>
                    <p className='default_color'>{queue}</p>

                </div>
                <div className='main_mobile_header_table_row_planned '>
                    <p className='main_mobile_header_table_row_title red_color'>запланировано</p>
                    <p className='red_color'>{counts?.allPlannedCount || 0}</p>
                </div>
                <div className='main_mobile_header_table_row '>
                    <p className='main_mobile_header_table_row_title orange_color'>в работе</p>
                    <p className='orange_color'>{counts?.allWorkingCount || 0}</p>
                </div>
                <div className='main_mobile_header_table_row'>
                    <p className='main_mobile_header_table_row_title linear_color'>на паузе</p>
                    <p className='linear_color'>{counts?.allPausedCount || 0}</p>
                </div>

                <div className='main_mobile_header_table_row '>
                    <p className='main_mobile_header_table_row_title green_color'>завершено</p>
                    <p className='green_color'>{counts?.allFinishedCount || 0}</p>
                </div>
                <div className='main_mobile_header_table_row '>
                    <p className='main_mobile_header_table_row_title gray_color'>отменено</p>
                    <p className='gray_color'>{counts?.allcancledCount || 0}</p>
                </div>

            </div>

            {
                choicedSubdivisions?.map(((group) => <GroupRowMobile key={group.subdivision.uuid} group={group} />))
            }

        </div >
    )
}

export default MainMobile