import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import './Arrows.css'

const Arrows = ({ onPrevious,onNext }) => {
  return (
    <div className='Departments__Arrows'>
        <CaretLeftOutlined className='Departments__Arrow' onClick={onPrevious} style={{fontSize: 35}}/>
        <CaretRightOutlined className='Departments__Arrow' onClick={onNext} style={{fontSize: 35}}/>
    </div>
  )
}

export default Arrows