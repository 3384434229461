import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./Updating.css";


const Updating = ({ noData }) => {
    const { timeout } = useSelector((state) => state.ui);

    useEffect(() => {
        clearInterval(timeout);
    }, [timeout]);

    return (
        <div className="updating_block">
            <div className={noData ? '' : "updating_animate"}></div>
            <div className={`updating_content ${noData ? 'noData' : ""}`}>{noData ? 'Заявок нет' : 'Получение данных'}</div>
        </div>
    );
};

export default Updating;
