import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import BasicAuth from './BasicAuth'
import { useNavigate } from 'react-router-dom'

const Auth = ({ children }) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem('user_hash')) navigate('/login', { replace: true })
    }, [localStorage.getItem('user_hash')])

    const user_hash = localStorage.getItem('user_hash')

    return user_hash ? <div>{children}</div> : null
}

export default Auth