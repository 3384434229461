import React from 'react'

const PlanedCol = ({ planned }) => {
    const isZeroPlanned = planned?.red || planned?.orange || planned?.normal
    return (
        <div className="department_employe_count_mobile department_employe_count_list">
            {planned?.red ? <p className={"department_employe_counts_mobile bgRed"}>{planned.red}</p> : null}
            {planned?.orange ? <p className={"department_employe_counts_mobile bgOrange"}>{planned.orange}</p> : null}
            {planned?.normal ? <p className={"default_color"}>{planned.normal}</p> : null}
            {!isZeroPlanned ? <p className={"default_color"}>{0}</p> : null}
        </div>
    )
}

export default PlanedCol