import React from 'react'

const Queue = ({ counts }) => {

    const queue = (counts.planned.red || 0) + (counts.planned.orange || 0) + (counts.planned.normal || 0) + counts.working.count + counts.paused.count

    return (
        <p className='department_employe_count_mobile default_color'>{queue}</p>
    )
}

export default Queue