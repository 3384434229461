import { toast } from "react-toastify"

export const createNotify = (payload) => {
   
    toast.warning(payload, {
        toastStyle: {
            background: 'yellow',
            color: 'black',
        },
    })
}