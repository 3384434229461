import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, nestApi } from "../axios/axios.config";
import { createNotify } from "../../global/utils/utils";
import { apiConfig } from "../config";

const initialState = {
    loginError: "",
    needRefresh: false,
    user_hash: "",
    stayLogged: false,
    logged: false,
    permissions: [],
    userSkills: [],
    allSkills: null,
    userInfo: {
        full_name: "",
        post: "",
        phone: "",
        it: "",
        email: "",
        photo: "",
        experience: {
            days: 0,
            months: 0,
            years: 0
        }
    }
};




export const loginWithPass = createAsyncThunk(
    'auth/loginWithPass',
    async ({ login, password, navigate, needRefresh: refresh }, { dispatch }) => {
        try {
            const data = { login, password, refresh }
            const response = await nestApi.post(`/api/login/lk-auth`, data, { headers: { Authorization: apiConfig.authToken } });
            dispatch(setUser(response.data.data.hash))
            // await dispatch(apiGetOwnRoles({ finishLoading: false, startLoading: false }))
            localStorage.setItem("user_hash", response.data.data.hash)
            dispatch(setNeedRefresh(false))
            navigate('/', { replace: true })
        } catch (e) {
            setTimeout(() => {
                if (!e?.response?.status) {
                    createNotify('Сервер не отвечает')
                    createNotify('Сервер не отвечает')
                } else if (e.response.status === 500) {
                    createNotify('Сервер не отвечает')
                } else if (e.response.data.errors.code === "USER_NOT_FOUND") {
                    dispatch(setNeedRefresh(true))
                    createNotify('Пользователь не синхронизирован, введите данные для входа повторно')
                } else {
                    createNotify('Неправильный логин или пароль')
                }
            }, 1000)
        }
    }
)


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setStayLogged: (state, action) => {
            state.stayLogged = action.payload
        },
        setUser: (state, action) => {
            state.user_hash = action.payload
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        setNeedRefresh: (state, action) => {
            state.needRefresh = action.payload
        },
        setUserSkills: (state, action) => {
            state.userSkills = action.payload
        },
        setAllSkills: (state, action) => {
            state.allSkills = action.payload
        },
        setUserPermissions: (state, { payload }) => {
            state.permissions = payload
        },
        setLogged: (state, { payload }) => {
            state.logged = payload
        }
    }
})

export const { setAllSkills, setLoginError, setStayLogged, setUser, setUserInfo, setNeedRefresh, setUserPermissions, setLogged, setUserSkills } = authSlice.actions;

export default authSlice.reducer;
