import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import NameCol from './columns/NameCol'
import QueueCol from './columns/Queue'
import PlanedCol from './columns/PlanedCol'
import WorkingCol from './columns/WorkingCol'
import PausedCol from './columns/PausedCol'
import FinishedCol from './columns/FinishedCol'
import CancledCol from './columns/CancledCol'
import accounting from '../../../assests/imgs/subdivisions/accounting.png'
import expert_fields from '../../../assests/imgs/subdivisions/expert_fields.png'
import outstaffing from '../../../assests/imgs/subdivisions/outstaffing.png'
import project_managers from '../../../assests/imgs/subdivisions/project_managers.png'
import remote_support from '../../../assests/imgs/subdivisions/remote_support.png'
import repairing from '../../../assests/imgs/subdivisions/repairing.png'
import service_center from '../../../assests/imgs/subdivisions/service_center.png'
import sales_department from '../../../assests/imgs/subdivisions/sales_department.png'
import server_experts from '../../../assests/imgs/subdivisions/server_experts.png'
import Line_1 from '../../../assests/imgs/subdivisions/linia_1.png'
import Line_2 from '../../../assests/imgs/subdivisions/linia_2.png'
import Line_3 from '../../../assests/imgs/subdivisions/linia_3.png'
import ShowHideGroup from '../../../assests/imgs/icons/showHIdeGroup.png'

const uuids_img = {
    '93c0d125-ec7e-11e8-80de-c4346b227f9c': Line_1,
    '9a802dac-ec7f-11e8-80de-c4346b227f9c': Line_2,
    '08f6a6aa-0517-11e9-80e0-c4346b227f9c': Line_3
}

const imgs = {
    'd517c016-d41e-11ea-80f7-c4346b227f9c': {
        img: remote_support,
        width: 53,
    },
    '11db1e7e-d41f-11ea-80f7-c4346b227f9c': {
        img: sales_department,
        width: 25,
        height: 25
    },
    '23b49f17-d41f-11ea-80f7-c4346b227f9c': {
        img: accounting,
        width: 25,
        height: 25
    },
    '991f9bb8-16d8-11ed-868d-c4346bb7a003': {
        img: outstaffing,
        width: 25,
        height: 25
    },
    'e99f9b53-d41e-11ea-80f7-c4346b227f9c': {
        img: expert_fields,
        width: 25,
        height: 25
    },
    'f354beb0-716d-11ed-868f-c4346bb7a003': {
        img: repairing,
        width: 25,
        height: 25
    },
    'f0fbd43b-d41e-11ea-80f7-c4346b227f9c': {
        img: service_center,
        width: 25,
        height: 25
    },
    'c003e454-3972-11ed-868e-c4346bb7a003': {
        img: project_managers,
        width: 25,
        height: 25
    },
    'd552f9cc-5b59-11ed-868f-c4346bb7a003': {
        img: server_experts,
        width: 25,
        height: 25
    },
}

const GroupRowMobile = ({ group }) => {

    const [show, setShow] = useState(true)

    const { allSubdivisionsList } = useSelector(state => state.implementers)

    const answer = allSubdivisionsList.find((department) => {
        return department.list.find(sub => sub.subdivision_uuid === group.subdivision.uuid)
    })

    const groupImg = uuids_img[group.subdivision.uuid] || imgs[answer.group_uuid]?.img
    const groupImgWidth = imgs[answer.group_uuid]?.width
    const groupImgHeight = imgs[answer.group_uuid]?.height


    const showOrHide = () => setShow(!show)

    return (
        <>
            <div key={group.subdivision.uuid} >
                <div className='main_mobile_footer' onClick={showOrHide}>
                    <div>
                        <img className={show ? '' : 'rotate180'} src={ShowHideGroup} />
                    </div>

                    <img src={groupImg} style={{ width: groupImgWidth, height: groupImgHeight }} />
                    <p>{group.subdivision.name_rus}</p>
                </div>
                <div style={{ display: 'flex' }}>
                    {
                        show ? <>
                            <div className='main_mobile_header_table_row_name minWidth290'>
                                {group.list.map(((implementer, i) => <NameCol key={i} name={implementer.name_rus} />))}
                            </div>
                            <div className='main_mobile_header_table_row'>
                                {group.list.map(((implementer, i) => <QueueCol key={i} counts={implementer.counts} />))}
                            </div>
                            <div className='main_mobile_header_table_row_planned '>
                                {group.list.map(((implementer, i) => <PlanedCol key={i} planned={implementer.counts.planned} />))}
                            </div>
                            <div className='main_mobile_header_table_row '>
                                {group.list.map(((implementer, i) => <WorkingCol key={i} working={implementer.counts.working} />))}
                            </div>
                            <div className='main_mobile_header_table_row'>
                                {group.list.map(((implementer, i) => <PausedCol key={i} paused={implementer.counts.paused} />))}
                            </div>
                            <div className='main_mobile_header_table_row '>
                                {group.list.map(((implementer, i) => <FinishedCol key={i} finished={implementer.counts.finished} />))}
                            </div>
                            <div className='main_mobile_header_table_row '>
                                {group.list.map(((implementer, i) => <CancledCol key={i} cancled={implementer.counts.cancled} />))}
                            </div>
                        </>
                            : null
                    }
                </div>


            </div>
        </>
    )
}

export default GroupRowMobile